import { getPlayer, prompts, ballState, scatterTheBall, countTackleZones, otherTeam, findPlayerInPosition, moves, rollForAgility } from './game';
import { measurePassLength } from './utils'

export function takePassAction(G, ctx, location) {

  const activePlayer = getPlayer(G, G.activePlayerID);
  const passLength = measurePassLength(activePlayer.location, location);
  const passingTackleZones = countTackleZones(G, otherTeam(activePlayer.team), activePlayer.location);

  activePlayer.action.push({ move: moves.THROW, coordinate: location });
  activePlayer.movesRemaining = 0;

  let coachPrompt = {
      type: prompts.PASS,
      coach: activePlayer.team,
      actingPlayerID: { team: activePlayer.team, number: activePlayer.number},
      diceRoll: rollForAgility(ctx, activePlayer, passLength - passingTackleZones),
      coordinates: location,
      responses: ['accept']
    };

  G.historyLog.push({ type: 'pass', from: {x: G.ball.x, y: G.ball.y}, to: {...location} });
  G.historyLog.push({ type: 'throw', coordinates: activePlayer.location, player: activePlayer, diceRoll: [coachPrompt.diceRoll.roll]});

  // Automatic reroll for skill
  if (!coachPrompt.diceRoll.success) {

    if (activePlayer.stats.skills.includes('pass')) {

      coachPrompt.diceRoll = rollForAgility(ctx, activePlayer, passLength - passingTackleZones);
      G.historyLog.push({ type: 'throw-skillReroll', coordinates: activePlayer.location, player: activePlayer, diceRoll: [coachPrompt.diceRoll.roll]});
    }
    else if (G.teams[activePlayer.team].remainingRerolls > 0){
      // If there are team rerolls remaining, prompt the coach
      coachPrompt.responses.push('reroll');
    }
  }

  if (coachPrompt.responses.length === 1) {

    finishPassAction(G, ctx, coachPrompt, { response: coachPrompt.responses[0]});
  }
  else {

    G.coachPrompts.push(coachPrompt);
  }
}

export function finishPassAction(G, ctx , originalPrompt, response) {

  const activePlayer = getPlayer(G, originalPrompt.actingPlayerID);
  let   rollForPass  = originalPrompt.diceRoll;

  // If we are failing and the coach asked for a reroll
  if (!rollForPass.success &&
      response.response === 'reroll') {

    rollForPass = rollForAgility(ctx, activePlayer, rollForPass.modifier);
    G.historyLog.push({ type: 'throw-teamReroll', coordinates: activePlayer.location, player: activePlayer, diceRoll: [rollForPass.roll]});

    G.teams[activePlayer.team].remainingRerolls--;
  }

  // Finally resolve the pass
  if (rollForPass.success) {

    const passTarget = findPlayerInPosition(G, originalPrompt.coordinates);

    G.ball.x = originalPrompt.coordinates.x;
    G.ball.y = originalPrompt.coordinates.y;

    if (passTarget != null) {
      G.ball.state = ballState.ACCURATEPASS;
    }
    else {
      G.ball.state = ballState.DROPPED;
    }
  }
  else {

    // Scatter 3 times
    scatterTheBall(G, ctx, originalPrompt.coordinates, 3);

    G.ball.state = ballState.INACCURATEPASS;
  }

  // This ends the phase
  ctx.events.endPhase({ next: "pickAction"});
}
