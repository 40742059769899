import { Game, INVALID_MOVE } from 'boardgame.io/core';

import { Coord } from './utils'

import {takeMoveAction, finishDodgeAction } from './moveAction';
import {takeBlockAction, finishBLockAction } from './blockAction'
import {takePassAction, finishPassAction} from './passAction'

// Valid states for the player pieces
export const playerState = {
  READY : "ready",
  PRONE : "prone",
  STUNNED : "stunned",
  STUNNED_NOW : "stunnedNow",
  KO : "KO",
  CASUALTY : "casualty",
  BENCH : "bench",
}

export const ballState = {
  ATREST : "atRest",
  INHAND : "inHand",
  BOUNCING : "bouncing", // Randomly bouncing ball
  BOUNCING_ACTIVE : "bouncing.active",   // Dropped by the active team ()
  ACCURATEPASS: "accuratePass",
  INACCURATEPASS: "inaccuratePass",
}

// Valid actions for the player
export const playerAction = {
  MOVE : "move",
  BLOCK : "block",
  BLITZ : "blitz",
  PASS : "pass",
  FOUL : "foul",
}

export const gamePhase = {
  PICKACTION: "pickAction",
  PLAYERMOVE: "playerMove",
  TEAMTURNEND: "teamTurnEnd",
  VALIDATEROLL: "validateRoll",
  VALIDATEBLOCKROLL: "validateBlockRoll",
  MOVETURNMARKER: "moveTurnMarker",
  SETUP: "setup",
  KICKOFF: "kickoff"
}

export const moves = {
  STARTACTION: 'startAction',
  MOVE: 'move',
  BLOCK: 'block',
  THROW: 'throw',
  END: 'end',
  REROLL: 'reroll',
  PICKBLOCKINGDICE: 'pickDice',
  ACCEPTROLL: 'acceptRoll',
  ENDTURN: 'endTurn'
}

export const prompts = {
  DODGE: 'dodge',
  BLOCK: 'block',
  PASS: 'pass',
  PICKUP: 'pickup'
}

export const fieldSize = {
  width: 15,
  length: 26
}

// All player states where the layer is physically on the board
export const playerStateOnBoard   = [ playerState.READY, playerState.PRONE, playerState.STUNNED, playerState.STUNNED_NOW];
const playerStateAvailable = playerStateOnBoard.concat([ playerState.BENCH]);

const promptResponseHandlers = {
  dodge: finishDodgeAction,
  block: finishBLockAction,
  pass: finishPassAction,
  pickup: finishPickupAction,
}

// Loads the player object from the player ID
export function getPlayer(G, playerID) {

  if (playerID == null)
    return null;

  let foundPlayer = G.players.find( player => ( (player.number === playerID.number) && (player.team === playerID.team)));

  return foundPlayer;
}

// Finds the player record that occupies a specific coordinate on the board (null if none)
export function findPlayerInPosition(G, position) {

  return G.players.find( player => (Coord.isSame(player.location, position) && playerStateOnBoard.includes(player.state)));
}

export function countPlayersOnField(G) {

  let results = [{
                    available: 0,
                    onField: 0,
                    onCenterline: 0,
                    inLeftWide: 0,
                    inRightWide: 0
                 },{
                    available: 0,
                    onField: 0,
                    onCenterline: 0,
                    inLeftWide: 0,
                    inRightWide: 0
                 }];

  G.players.forEach( (player, index) => {

      let teamTotal = results[player.team];

      if (playerStateAvailable.includes(player.state)) {

        teamTotal.available++;

        if (playerStateOnBoard.includes(player.state)) {

          teamTotal.onField++;

          if (player.location.x < 4) {

            teamTotal.inLeftWide++;
          }
          else if (player.location.x > 10) {

            teamTotal.inRightWide++;
          }
          else if ( (player.location.y === 12) ||
                    (player.location.y === 13) ) {

            teamTotal.onCenterline++;
          }
        }
      }
    });

  return results;
}


// Given a team ID, returns the ID of the other team
export function otherTeam(currentTeam) {
  return (currentTeam + 1) % 2;
}

// Count the number of active tackle zones for the given team
// in the target square
export function countTackleZones(G, forTeam, location) {

  return G.players.reduce(
    (total, currentValue) => { return ( ( (currentValue.state === playerState.READY) &&
                                          (currentValue.team === forTeam) &&
                                          Coord.isAdjacent(currentValue.location, location) ) ? total + 1 : total ) },
    0
  );
}

// Will make a modified roll for agility for the given player
// true if roll succeeds
export function rollForAgility(ctx, player, modifier) {

  const roll = ctx.random.D6();
  const agilityTable = [ 6, 5, 4, 3, 2, 1];

  // Auto fail
  if (roll === 1)
    return { roll : roll, modifier: modifier, success: false };;

  // Auto success
  if (roll === 6)
    return { roll : roll, modifier: modifier, success: true };;

  return { roll : roll, modifier: modifier, success: ((roll + modifier) >= agilityTable[Math.min(6, player.stats.AG) - 1]) };
}


// Will make a modified roll for injury for the given player
// true if roll succeeds
function rollForInjury(G, ctx, player, modifier = 0) {

  const armorRoll  = ctx.random.D6(2);
  const armorTotal = armorRoll[0] + armorRoll[1];

  G.historyLog.push({ type: 'armorRoll', coordinates: player.location, player: player, diceRoll: armorRoll, result: ''});

  if (armorTotal > player.stats.AV) {

    const injuryRoll  = ctx.random.D6(2);
    const injuryTotal = injuryRoll[0]+injuryRoll[1];
    let   result = playerState.PRONE;

    if (injuryTotal <= 7)
      result = playerState.STUNNED_NOW;
    else if (injuryTotal <= 9)
      result = playerState.KO;
    else
      result = playerState.CASUALTY;

    G.historyLog.push({ type: 'injuryRoll', coordinates: player.location, player: player, diceRoll: injuryRoll, result: result });

    return result;
  }

  return playerState.PRONE;
}


function clearStunnedPlayers(G, players, ctx) {
  return players.map( curPlayer => (curPlayer.team === G.activeTeam &&
                                    (curPlayer.state === playerState.STUNNED) ) ? {...curPlayer, state : playerState.PRONE}: {...curPlayer})
}

function clearStunnedNowPlayers(players) {
  return players.map( curPlayer => (curPlayer.state === playerState.STUNNED_NOW) ? {...curPlayer, state : playerState.STUNNED}: {...curPlayer})
}

function clearPlayerAction(players) {
  return players.map( curPlayer => ({...curPlayer, action : null }));
}

function moveTurnMarker(G, ctx) {

  // Actually move the marker
  G.teams[G.activeTeam].turnMarker = G.teams[G.activeTeam].turnMarker + 1;

  // The next phase is going to pick an action
  ctx.events.endPhase({next: 'pickAction'});

}

// Runs all the upkeep when a team turn ends
function endTurnMaintenance(G, ctx) {

  if (G.teams[0].turnMarker >= 8 &&
      G.teams[1].turnMarker >= 8 ) {

    // TODO: second half
    ctx.events.endGame( (G.teams[0].score > G.teams[1].score) ? G.teams[0].name : (
                        (G.teams[0].score < G.teams[1].score) ? G.teams[1].name :
                        'Draw'));
  }

  // Make sure we're going back to picking an action
  ctx.events.endPhase({next: 'moveTurnMarker'});

  G.players = clearPlayerAction(clearStunnedNowPlayers(clearStunnedPlayers(G, G.players, ctx)));
  G.activeTeam = otherTeam(G.activeTeam);
}

// Runs after every move
function cleanUpAfterMove(G, ctx) {

  // Don't resolve for touchdown or rest the ball when we still have a
  // pending coach prompt
  if (G.coachPrompts.length === 0) {
    checkBallAtRest(G, ctx);
    checkAutoEndMove(G, ctx);
    checkTouchDown(G, ctx);
  }
}

function processTouchDown(G, ctx, team) {

  G.teams[team].score = G.teams[team].score + 1;

  if (G.activeTeam === team) {

    G.activeTeam = otherTeam(G.activeTeam);
  }
  else {
    // We don't switch the active team but we spend the turn.
    G.teams[team].turnMarker = G.teams[team].turnMarker + 1
  }

  // Go back to the setup
  ctx.events.endPhase({next: gamePhase.SETUP });
}

function checkAutoEndMove(G, ctx) {
  if (ctx.phase === gamePhase.PLAYERMOVE) {

    if ( (G.coachPrompts.length === 0) &&
         (getValidMovesForPlayer(G, ctx).length <= 2) ) {

       ctx.events.endPhase({next: gamePhase.PICKACTION });
    }
  }
}

function checkTouchDown(G, ctx) {

  // A touchdown only happens if the ball is in a player's hand
  if (G.ball.state === ballState.INHAND) {

    let playerWithBall = findPlayerInPosition(G,G.ball);

    // Player that has the ball is in the opposite team's endzone
    if (playerWithBall &&
        (playerWithBall.team === 1) &&
        (G.ball.y === 0) ) {

      processTouchDown(G, ctx, playerWithBall.team);
    }
    else if (playerWithBall &&
             (playerWithBall.team === 0) &&
             (G.ball.y === (fieldSize.length - 1) ) ) {

      processTouchDown(G, ctx, playerWithBall.team);
    }
  }
}

// Validates that the state of the ball is consistant. Will
// take care of passes, dropped balls, and bouncing
function checkBallAtRest(G, ctx) {

  // Do we have to pickup the ball
  switch (G.ball.state) {

    case ballState.INHAND:
      // Do nothing
      break;

    default:

      let playerWithBall = findPlayerInPosition(G, G.ball);

      // There's a player with the ball, try to pick it up
      if (playerWithBall != null) {

        if (playerWithBall.state === playerState.READY) {

          takePickupAction(G, ctx, playerWithBall);
        }
        else
        {
          scatterTheBall(G, ctx, G.ball);

          G.ball.state = ballState.BOUNCING;

          checkBallAtRest(G, ctx);
        }
      }
      else {

        // We're done bouncing/cathching... , check if this will create a turnover
        // for the for active team
        if ([ballState.ACCURATEPASS, ballState.INACCURATEPASS, ballState.BOUNCING_ACTIVE].includes(G.ball.state)) {

          // TODO: Use utility to end the team turn.
          endPlayerTurn(G, ctx);
          ctx.events.endPhase({ next: 'teamTurnEnd'});
        }

        G.ball.state = ballState.ATREST;
      }
      break;
  }
}

function takePickupAction(G, ctx, actingPlayer) {

  let baseModifier = ( (G.ball.state === ballState.ACCURATEPASS) || (G.ball.state === ballState.ATREST) ? 1 : 0)

  let coachPrompt = {
      type: prompts.PICKUP,
      coach: actingPlayer.team,
      actingPlayerID: { team: actingPlayer.team, number: actingPlayer.number},
      diceRoll: rollForAgility(ctx, actingPlayer, baseModifier - countTackleZones(G, otherTeam(actingPlayer.team), actingPlayer.location)),
      coordinates: actingPlayer.location,
      responses: ['accept']
    };

  G.historyLog.push({ type: 'pickup', coordinates: actingPlayer.location, player: actingPlayer, diceRoll: [coachPrompt.diceRoll.roll]});

  if (!coachPrompt.diceRoll.success) {

    // Do I have a skills reroll
    let skillReroll = null;

    switch (G.ball.state) {
      case ballState.ACCURATEPASS:
      case ballState.INACCURATEPASS:

        skillReroll = actingPlayer.stats.skills.find( (skill) => (skill ==='catch'));
        break;
      default:

        skillReroll = actingPlayer.stats.skills.find( (skill) => (skill ==='sure hands'));
        break;
    }

    if (skillReroll != null) {

      coachPrompt.diceRoll = rollForAgility(ctx, actingPlayer, coachPrompt.diceRoll.modifier);

      G.historyLog.push({ type: 'pickup-skillReroll', coordinates: actingPlayer.location, player: actingPlayer, diceRoll: [coachPrompt.diceRoll.roll]});
    }
    else if (G.teams[actingPlayer.team].remainingRerolls > 0){
      // If there are team rerolls remaining, prompt the coach
      coachPrompt.responses.push('reroll');
    }
  }

  // Player failed to pickup
  if (coachPrompt.responses.length === 1) {

    finishPickupAction(G, ctx, coachPrompt, { response: coachPrompt.responses[0]});
  }
  else {

    G.coachPrompts.push(coachPrompt);
  }
}

function finishPickupAction(G, ctx, originalPrompt, response) {

  const actingPlayer = getPlayer(G, originalPrompt.actingPlayerID);
  let   rollForPass  = originalPrompt.diceRoll;

  // If we are failing and the coach asked for a reroll
  if (!rollForPass.success &&
      response.response === 'reroll') {

    rollForPass = rollForAgility(ctx, actingPlayer, rollForPass.modifier);
    G.historyLog.push({ type: 'pickup-teamReroll', coordinates: actingPlayer.location, player: actingPlayer, diceRoll: [rollForPass.roll]});

    G.teams[actingPlayer.team].remainingRerolls--;
  }

  if (rollForPass.success) {

    // We're done bouncing/cathching... , check if this will create a turnover
    // for the for active team
    if ([ballState.ACCURATEPASS, ballState.INACCURATEPASS, ballState.BOUNCING_ACTIVE].includes(G.ball.state) &&
        G.activeTeam !== actingPlayer.team) {

      // TODO: Use utility to end the team turn.
      endPlayerTurn(G, ctx);
      ctx.events.endPhase({ next: 'teamTurnEnd'});
    }

    G.ball.state = ballState.INHAND;
  }
  else {

    scatterTheBall(G, ctx, G.ball);

    // If this was an accurate pass, it is now considered a dropped ball
    if (G.ball.state === ballState.ACCURATEPASS)
      G.ball.state = ballState.BOUNCING_ACTIVE;

    checkBallAtRest( G, ctx);
  }
}

const defaultValidMoves = {

  move:  [ moves.MOVE, moves.END, moves.ENDTURN],
  blitz: [ moves.MOVE, moves.BLOCK, moves.END, moves.ENDTURN],
  block: [ moves.BLOCK, moves.END, moves.ENDTURN],
  pass:  [ moves.MOVE, moves.THROW, moves.END, moves.ENDTURN],
}

function getValidMovesForPlayer(G, ctx) {

  let   activePlayer  = getPlayer(G, G.activePlayerID);
  let   validMoves = [];

  if ( (activePlayer == null) ||
       (activePlayer.action == null) ||
       (activePlayer.state !== playerState.READY) ||
       (activePlayer.movesRemaining <= 0)  )
    return validMoves;

  const playerHasBall = (Coord.isSame(G.ball, activePlayer.location) && (G.ball.state === ballState.INHAND));

  validMoves = defaultValidMoves[activePlayer.action[0].action];

  // Remove BLOCK or THROW if it has already happened
  validMoves = validMoves.filter( (move) => !( (move === moves.BLOCK || move === moves.THROW) &&
                                               (activePlayer.action.find((logitem) => { return (logitem.move && (logitem.move === move)) }) != null) ) );

  // Remove THROW if the player is not holding the balls
  validMoves = validMoves.filter( (move) => ( (move !== moves.THROW) || playerHasBall) );

  return validMoves;
}

export function getValidActionsForPlayer(G, ctx, player) {

  var validActions = [];

  // Only build actions if the player has not moved yet
  if ( (player.action == null) && (G.activeTeam === player.team) ) {

    switch (player.state) {
      case playerState.PRONE:
        validActions.push(playerAction.MOVE);
        break;
      case playerState.READY:
        validActions.push(playerAction.MOVE);
        validActions.push(playerAction.BLOCK);

        if (G.players.find( (player) => (player.action && (player.action[0].action === playerAction.BLITZ) ) ) == null)
          validActions.push(playerAction.BLITZ);

        if (G.players.find( (player) => (player.action && (player.action[0].action === playerAction.PASS) ) ) == null)
          validActions.push(playerAction.PASS);
        break;
      default:
        // No valid actions in those cases
        break;
    }
  }

  return validActions
}

export function playerGoesDown(G, ctx, playerGoingDown) {

  // Player falls
  playerGoingDown.movesRemaining = 0;
  playerGoingDown.state = rollForInjury(G, ctx, playerGoingDown);

  // If player had the ball. bounce the ball.
  if ( (G.ball.state === ballState.INHAND) &&
       Coord.isSame(G.ball, playerGoingDown.location) ) {

    // The dropped ball will scatter once
    scatterTheBall(G, ctx, G.ball);

    // Then, if the ball holder that went down was on the active team,
    // we set the ball's state
    if (playerGoingDown.team === G.activeTeam)
      G.ball.state = ballState.BOUNCING_ACTIVE;
    else
      G.ball.state = ballState.BOUNCING;
  }
  else {
    // if the player going down was the active player, end the move
    if ( (playerGoingDown.team === G.activeTeam) &&
         (playerGoingDown.team === G.activePlayerID.team) &&
         (playerGoingDown.number === G.activePlayerID.number) ) {

      ctx.events.endPhase({ next: "pickAction"});
    }
  }
}


export function isInbounds(location) {

  return ( (location.x >= 0) &&
           (location.x <  fieldSize.width) &&
           (location.y >= 0) &&
           (location.y < fieldSize.length) );
}


export function scatterTheBall(G, ctx, currentBallPos, scatterCount = 1, distance = 1) {

  const scatterTable = [ {x: -1, y:-1}, {x:-1, y: 0}, {x: -1, y:+1},
                         {x:  0, y:-1},               {x:  0, y:+1},
                         {x: +1, y:-1}, {x:+1, y: 0}, {x: +1, y:+1} ];
  const scatterRoll = ctx.random.D8() - 1;

  var newBallPos = {...currentBallPos};

  // Move the ball
  while (distance-- > 0) {
    newBallPos = { x: newBallPos.x + scatterTable[scatterRoll].x,
                   y: newBallPos.y + scatterTable[scatterRoll].y};

    // Keep it in bounds for now
    if (newBallPos.x < 0)
      newBallPos.x = 0;

    if (newBallPos.x >= fieldSize.width )
      newBallPos.x = fieldSize.width -1;

    if (newBallPos.y < 0)
      newBallPos.y = 0;

    if (newBallPos.y > fieldSize.length )
      newBallPos.y = fieldSize.length-1;
  }

  // Add this to the history log
  G.historyLog.push({ type: 'scatter', from: {...currentBallPos}, to: newBallPos});

  G.ball.x = newBallPos.x;
  G.ball.y = newBallPos.y;

  if (scatterCount > 1)
    scatterTheBall(G, ctx, G.ball, scatterCount - 1);
}

function wakeupTheDead(G, ctx) {
  // Stunned & prone players stand up
  G.players = G.players.map( (player) => ( {...player,
                                            state: ( [playerState.STUNNED, playerState.PRONE, playerState.STUNNED_NOW].includes(player.state) ?
                                                     playerState.READY :
                                                     player.state),
                                            action: null,
                                          }));

  // KO players go on the bench on a 4+
  G.players = G.players.map( (player) => ( {...player,
                                            state: ( ( (player.state === playerState.KO) &&
                                                       (ctx.random.D6() >= 4) ) ?
                                                     playerState.BENCH :
                                                     player.state),
                                          }));
}

function fakePlacePlayers(G, ctx) {

  let teamX = [0, 0];

  G.players = G.players.map( (player, index) => {

                                  switch (player.state) {

                                    case playerState.BENCH:
                                    case playerState.READY:

                                      if (teamX[player.team] < 11) {

                                        let relPos = ( (teamX[player.team] % 2) ? -1 : 1 ) * (Math.floor(teamX[player.team]/2) + 1);

                                        teamX[player.team]++;

                                        return ( {...player,
                                                  state: playerState.READY,
                                                  location: { x: 7 + relPos, y: (fieldSize.length / 2) + player.team - 1}

                                                });
                                      }
                                      return { ...player, state: playerState.BENCH };
                                    default:
                                      return player;
                                  }
                              });

}

function fakeSetup(G, ctx) {

  wakeupTheDead(G,ctx);

  fakePlacePlayers(G, ctx);

  G.ball = {x: 7, y: 7, state: ballState.ATREST };
}

function endPlayerTurn(G, ctx) {

  const activePlayer = getPlayer(G, G.activePlayerID);

  if (activePlayer)
    activePlayer.action.push({move: moves.END});

  ctx.events.endPhase({next: gamePhase.PICKACTION });
}

export function setupIsValid(G, team) {

  let playerOnField = countPlayersOnField(G)[team];

  // Deal with the exception of having less than 3 players available
  if ( (playerOnField.available < 3) &&
       (playerOnField.onCenterline === playerOnField.available) ) {

    return true;
  }

  return ( (playerOnField.onField <= 11) &&
           (playerOnField.onCenterline >= 3) &&
           (playerOnField.inLeftWide <= 2) &&
           (playerOnField.inRightWide <= 2) );

}

// Moves a player on the board, will also move the ball if the player
// has possession
export function movePlayer(G, ctx, player, location){

  if ( (G.ball.state === ballState.INHAND) &&
       Coord.isSame(G.ball, player.location) ) {

    G.ball.x = location.x;
    G.ball.y = location.y;
  }

  // Add this to the history log
  G.historyLog.push({ type: 'move', from: player.location, to: location});

  player.location = location;
}

function endTurnIf(G, ctx) {

  const currentCoach = parseInt(ctx.currentPlayer);

  if (G.coachPrompts.length > 0) {

    return (G.coachPrompts[G.coachPrompts.length-1].coach !== currentCoach);
  }
  else {

    return (G.activeTeam !== currentCoach);
  }
}

const BloodBowl = Game( {

  setup: () => ({

     teams: [
       {
         name: "Orc",
         teamRerolls: 2,
         remainingRerolls: 2,
         color: '#F00',
         turnMarker: 0,
         score: 0,
       },
       {
         name: "Humans",
         teamRerolls: 2,
         remainingRerolls: 2,
         color: '#00F',
         turnMarker: 0,
         score: 0,
       },
     ],

     players: [
       {
         number: 1,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 1},
         stats: { name: "Blitzer", shortName: "B", MA: 6, ST: 3, AG: 3, AV: 9, skills: ['block'] }
       },
       {
         number: 2,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 1},
         stats: { name: "Blitzer", shortName: "B", MA: 6, ST: 3, AG: 3, AV: 9, skills: ['block'] }
       },
       {
         number: 3,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Black Orc Blocker", shortName: "BOB", MA: 4, ST: 4, AG: 2, AV: 9, skills: [] }
       },
       {
         number: 4,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Black Orc Blocker", shortName: "BOB", MA: 4, ST: 4, AG: 2, AV: 9, skills: [] }
       },
       {
         number: 5,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Thrower", shortName: "T", MA: 5, ST: 3, AG: 3, AV: 8, skills: ['sure hands', 'pass'] }
       },
       {
         number: 6,
         team: 0,
         state: playerState.BENCH,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Thrower", shortName: "T", MA: 5, ST: 3, AG: 3, AV: 8, skills: ['sure hands', 'pass'] }
       },
       {
         number: 7,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 8,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 9,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 10,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 11,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 12,
         team: 0,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 2, y: 1},
         stats: { name: "Lineman", shortName: "L", MA: 5, ST: 3, AG: 3, AV: 9, skills: [] }
       },
       {
         number: 1,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Blitzer", shortName: "Bl", MA: 7, ST: 3, AG: 3, AV: 8, skills: ['block'] }
       },
       {
         number: 2,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Blitzer", shortName: "Bl", MA: 7, ST: 3, AG: 3, AV: 8, skills: ['block'] }
       },
       {
         number: 3,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L", MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 4,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L", MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 5,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L", MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 6,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L",MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 7,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L",MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 8,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Lineman", shortName: "L",MA: 6, ST: 3, AG: 3, AV: 8, skills: [] }
       },
       {
         number: 9,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Catcher", shortName: "C", MA: 8, ST: 2, AG: 3, AV: 7, skills: ['catch', 'dodge'] }
       },
       {
         number: 10,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Catcher", shortName: "C", MA: 8, ST: 2, AG: 3, AV: 7, skills: ['catch', 'dodge'] }
       },
       {
         number: 11,
         team: 1,
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Thrower", shortName: "T", MA: 6, ST: 2, AG: 3, AV: 8, skills: ['sure hands', 'pass'] }
       },
       {
         number: 12,
         team: 1,
         position: "Thrower",
         state: playerState.READY,
         movesRemaining: 3,
         action: null,
         location: { x: 1, y: 2},
         stats: { name: "Thrower", shortName: "T", MA: 6, ST: 2, AG: 3, AV: 8, skills: ['sure hands', 'pass'] }
       },
     ],
     activeTeam : 0,
     kickingTeam : 0,
     coachPrompts: [],
     historyLog: [],
     ball: { x: 1, y: 1, state: ballState.ATREST},
     activePlayerID: null,
     activeAction: null,
  }),


  moves: {

    // Picks a player as the active player and start them up
    startAction(G, ctx, playerID, action) {

      const activePlayer = getPlayer(G, playerID);
      const validActionsForPlayer = getValidActionsForPlayer(G, ctx, activePlayer);

      // Basic validation
      if (!validActionsForPlayer.includes(action)) {
        return INVALID_MOVE;
      }

      // Reset the # of moves for that user
      activePlayer.movesRemaining = activePlayer.stats.MA;

      // Create the action log for that user
      activePlayer.action = [ { type: moves.STARTACTION, action : action} ];

      // Mark this user as the active user
      G.activePlayerID = { team: activePlayer.team, number: activePlayer.number };;

      // Stand-up the player if the player was prone
      if (activePlayer.state === playerState.PRONE) {

        activePlayer.movesRemaining = activePlayer.movesRemaining - 3;
        activePlayer.state = playerState.READY;
      }

      // Start the playerMove phase
      ctx.events.endPhase({ next: gamePhase.PLAYERMOVE });
    },

    throw(G, ctx, location) {

      G.historyLog = [];
      return takePassAction(G, ctx, location);
    },

    block(G, ctx, location) {

      G.historyLog = [];
      return takeBlockAction(G, ctx, location);
    },

    respondToPrompt(G, ctx, response) {

      if (G.coachPrompts.length === 0)
        return INVALID_MOVE;

      let originalPrompt = G.coachPrompts.pop();

      return promptResponseHandlers[originalPrompt.type](G, ctx, originalPrompt, response);
    },

    // Player turn ends
    end(G, ctx) {

      endPlayerTurn(G, ctx);
    },

    // End the team's turn
    endTurn(G,ctx) {

      if (ctx.phase === 'setup') {

        if (!setupIsValid(G, G.activeTeam))
          return INVALID_MOVE;

        // if both players have gone, move on
        if (G.activeTeam !== G.kickingTeam) {

          ctx.events.endPhase({next: 'kickoff'});
        }

        G.activeTeam = otherTeam(G.activeTeam);

//        ctx.events.endTurn();
      }
      else {

        endPlayerTurn(G, ctx);

        ctx.events.endPhase({ next: 'teamTurnEnd'});
      }
    },

    placeBall(G, ctx, position) {

      // Kickoff scatter
      scatterTheBall(G, ctx, position, 1, ctx.random.D6());

      if (isInbounds(G.ball) &&
          Coord.isOnTeamSide(otherTeam(G.kickingTeam), G.ball) ) {

        // Successfull Kickoff
        G.ball.state=ballState.BOUNCING;
        checkBallAtRest(G, ctx);
      }
      else {

        // TODO: Touchback
        let player = G.players.find( (player) => { return ( (player.team === otherTeam(G.kickingTeam)) &&
                                                            (player.state === playerState.READY) ) } );

        if (player) {
          G.ball.x = player.location.x;
          G.ball.y = player.location.y;
          G.ball.state = ballState.INHAND;
        }
      }

      G.activeTeam = otherTeam(G.kickingTeam);

      ctx.events.endPhase({ next: 'moveTurnMarker'});
    },

    setupPlayer(G, ctx, playerID, newPosition) {

      let player = getPlayer(G, playerID);

      // [-1, -1] means bench
      if (Coord.isSame({x: -1, y: -1}, newPosition)) {
        player.state = playerState.BENCH

        return;
      }

      // Only can move players that are ready to play and only allowed to move that on the
      if (![playerState.READY, playerState.BENCH].includes(player.state) ||
          !Coord.isOnTeamSide(player.team, newPosition) ) {

        return INVALID_MOVE;
      }

      player.state = playerState.READY;
      player.location = newPosition;
    },

    move(G, ctx, location) {

      G.historyLog = [];

      takeMoveAction(G, ctx, location);
    }
  },

  flow: {
    startingPhase: 'setup',
    onMove: (G, ctx) => { return cleanUpAfterMove(G, ctx); },
    undoableMoves: [],
    endTurnIf: endTurnIf,

    phases: {

      setup: {
        allowedMoves: ['setupPlayer', 'endTurn'],
        onPhaseBegin: (G, ctx) => ( fakeSetup(G, ctx) ),
      },

      kickoff: {
        allowedMoves: ['placeBall'],
      },

      // This is an upkeep phase
      moveTurnMarker: {
        allowedMoves: [],
        onPhaseBegin: (G, ctx) => { moveTurnMarker(G, ctx);},
      },

      pickAction: {
        allowedMoves: (G, ctx) => ( (G.coachPrompts.length > 0) ? ['respondToPrompt'] : ['startAction', 'endTurn']),
        onPhaseBegin: (G, ctx) => ({ ...G, activePlayerID: null}),
      },

      playerMove: {
        allowedMoves: (G, ctx) => ( (G.coachPrompts.length > 0) ? ['respondToPrompt'] : getValidMovesForPlayer(G,ctx)),
        onPhaseBegin: (G, ctx) => { G.historyLog = [] },
      },

      teamTurnEnd: {
        onPhaseBegin: (G, ctx) => { return endTurnMaintenance(G, ctx);  }
      }

    },
  },
})

export default BloodBowl;
