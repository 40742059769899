//import React from 'react';

import { Client } from 'boardgame.io/react';

import GameUI from './game/gameui'
import BloodBowl from './game/game'


const App = Client({ game: BloodBowl, board: GameUI , debug: (!process.env.NODE_ENV || (process.env.NODE_ENV === 'development'))});

export default App;
