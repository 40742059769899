import React, { useState } from 'react'

import { playerStateOnBoard, playerState, setupIsValid, playerAction, prompts, gamePhase, getPlayer, moves, countPlayersOnField } from './game'
import { Coord } from './utils'
import Pitch from './pitch'
import History from './history'

import "./gameui.css"

// Moves that require a map location to perform
const mapMoves = ['move', 'block', 'throw'];

const Scoreboard = (props) => {
  return (

    <>
    <div className="scoreboard" style={{ height: window.innerHeight}}>
      {props.children}
    </div>

    </>
  );
}

const BlockDie = (props) => {

  return (
    <div className="blockDie">{props.die}</div>
  )
}

const Toolbar = (props) => {

  if (props.children.length === 0) {
    return null;
  }
  else {
    return (
      <div className="toolbar">
        {props.children}
      </div>
    );
  }
}

const CoachPrompt = (props) => {

  if (props.G.coachPrompts.length === 0)
    return null;

  let currentPrompt = props.G.coachPrompts[props.G.coachPrompts.length - 1]
  let actingPlayer  = getPlayer(props.G, currentPrompt.actingPlayerID);

  switch (currentPrompt.type) {
    case prompts.BLOCK:

      let defendingPlayer  = getPlayer(props.G, currentPrompt.defenderPlayerID);

      return (

        <div className="modalPopupStyle">
          <CoachInfo {...props}/>
          <h1>Block</h1>
          <div style={{ display:'flex', flexDirection: 'row'}}>
            <PlayerInfo selectedPlayer={actingPlayer} {...props} />
            <PlayerInfo selectedPlayer={defendingPlayer} {...props} />
          </div>
          <h2>Block Roll</h2>
          <div className="dieRoll">
          {
              currentPrompt.diceRoll.map( (blockDie, index) => (<BlockDie key={index} die={blockDie} />))
          }
          </div>
          <Toolbar>
          {
            currentPrompt.responses.map( (response) => (
              <button key={response} onClick={ () => { props.moves.respondToPrompt({ response: response })} }>{response}</button>
            ))
          }
          </Toolbar>
        </div>
      );
    case prompts.DODGE:
    case prompts.PASS:
    case prompts.PICKUP:
    default:
      return (

        <div className="modalPopupStyle">
          <CoachInfo {...props}/>
          <PlayerInfo selectedPlayer={actingPlayer} {...props} />
          <div>Player failed a {currentPrompt.type} roll</div>
          <div>Roll: {currentPrompt.diceRoll.roll} Modifier: {currentPrompt.diceRoll.modifier}</div>
          <Toolbar>
          {
            currentPrompt.responses.map( (response) => (
              <button key={response} onClick={ () => { props.moves.respondToPrompt({ response: response })} }>{response}</button>
            ))
          }
          </Toolbar>
        </div>
      );
  }
}

const GameBanner = (props) => {

  return (
    <div className="gameBanner">
      BloodBowl
    </div>
  );
}

const MarkerTrack = (props) => (

  <div className="turnTrack">
    <div className="label">{props.children}</div>
  {
    props.track.map((item, index) => <div key={"index:" + index} className={ 'box' + ((props.active === index) ? ' active' : ' ')}>
                                    {item}
                                   </div>)
  }
  </div>
)

const Team = (props) => {

  let currentTeam = props.G.teams[props.teamId];
  let turnTrack   = ['*','1','2','3','4','5','6','7','8','*'];
  let rerollTrack = [...Array(currentTeam.teamRerolls + 1).keys()];

  return (
    <div className={ 'team' + ((props.teamId === props.G.activeTeam) ? ' activeTeam' : '')}>
      <div className="teamID">
        <div className='logo' style={{ backgroundColor: currentTeam.color}}></div>
        <div className='name'>{currentTeam.name}</div>
        <div className='score'>{currentTeam.score}</div>
      </div>
      <MarkerTrack track={turnTrack} active={currentTeam.turnMarker}>Turn</MarkerTrack>
      <MarkerTrack track={rerollTrack} active={currentTeam.remainingRerolls}>Rerolls</MarkerTrack>
    </div>
  );
}

export function getTokenPosition(coordinates) {

  return { left: 1 + coordinates.x * 42, top:1 + coordinates.y * 42}
}


const Ball = (props) => {

  return (
    <div className="playerBox"
         style={getTokenPosition(props.G.ball)}>
      <div className="ballInCell">🏈</div>
    </div>
  );
}

const Player = (props) => {

  // TODO: activeplayer
  let teamColor      = (props.team) ? props.team.color : props.G.teams[props.player.team].color;
  let isActivePlayer = (props.activePlayerID &&
                        ('team' in props.activePlayerID) &&
                        (props.activePlayerID.team === props.player.team) &&
                        (props.activePlayerID.number === props.player.number));
  let playerSpent    = !isActivePlayer && (props.player.action!=null);

  console.log('<Player ' + JSON.stringify({ team: props.player.team, number: props.player.number}) + ' : ' + JSON.stringify(isActivePlayer));

  return (
    <div className="playerBox"
         onClick={ (e) => { e.stopPropagation(); props.onClickPlayer(props.player) } }
         style={getTokenPosition(props.player.location)}>
      <div className={"playerInCell " + ( isActivePlayer ? ' activePlayer' : '') + ( playerSpent ? ' spent' : '') + ' ' + props.player.state}
           style={{ backgroundColor: teamColor}}>
        <div>{props.player.number}</div>
        <div>{props.player.stats.shortName}</div>
      </div>
    </div>
  );
}

const MovingPlayerInfoScreen = (props) => {

  let selectedPlayer = ( props.G.activePlayerID ? getPlayer(props.G, props.G.activePlayerID) : null);

  let movingPlayerAction = [];

  if (props.ctx.phase === gamePhase.PLAYERMOVE) {
    movingPlayerAction = props.ctx.allowedMoves.filter((action) => !mapMoves.includes(action));
  }
  else {
    movingPlayerAction.push(moves.ENDTURN);
  }

  return (<CoachMessage>
            <h1>Player moving</h1>
            <PlayerInfo selectedPlayer={selectedPlayer} {...props} />
            <div style={{ display: 'inline'}}>
              {
                movingPlayerAction.map( (action) =>
                                         <button key={ "movingAction:" + action}
                                                 onClick={ () => { props.setSelectedPlayerID({}); props.moves[action]();} }>
                                            {action}
                                         </button>
                                        )
              }
            </div>
          </CoachMessage>);
}

const CoachMessage = (props) => {
  return (<div>{props.children}</div>);
}

const SetupInfoScreen = (props) => {

  let playerOnField = countPlayersOnField(props.G);

  return (<CoachMessage>
            <h1>{ (props.G.activeTeam === props.G.kickingTeam) ? 'Kicking ': 'Receving '} team setup</h1>
            <p>Move the players on your team to prepare for kickoff.</p>
            <p>Players on Field: {playerOnField[props.G.activeTeam].onField} (max 11)</p>
            <p>Players on centerline: {playerOnField[props.G.activeTeam].onCenterline} (min 3)</p>
            <p>Players in wide zones: {playerOnField[props.G.activeTeam].inLeftWide}/{playerOnField[props.G.activeTeam].inRightWide} (max 2 each)</p>
            <Toolbar>
              <button disabled={!setupIsValid(props.G, props.G.activeTeam)} onClick={ () => { props.moves.endTurn();} }>
                 Done
              </button>
            </Toolbar>
          </CoachMessage>);
}


const PickActionMessage = (props) => {

  return (<CoachMessage>
            <h1>Declare action</h1>
            <p>Select a player to declare an action</p>
            <button onClick={ () => { props.moves.endTurn();} }>
               End turn
            </button>
          </CoachMessage>);
}

const ActiveCoachArea = (props) => {

  if (props.G.coachPrompts.length > 0)
    return (<div className="coachArea"></div>);

  switch (props.ctx.phase) {

    case gamePhase.PICKACTION:
      return (<div className="coachArea"><PickActionMessage {...props} /></div>);

    case gamePhase.PLAYERMOVE:
    return (<div className="coachArea"><MovingPlayerInfoScreen {...props} /></div>);

    case gamePhase.SETUP:
      return (<div className="coachArea"><SetupInfoScreen {...props} /></div>);

    case gamePhase.KICKOFF:
      return (<div className="coachArea"><CoachMessage><h1>Kickoff</h1><p>Place the ball for kickoff.</p></CoachMessage></div>);

    default:
      return (<div className="coachArea"><CoachMessage>Hmmm.</CoachMessage></div>);
  }
}

const CoachInfo = (props) => {

  let playerTeam = props.G.teams[parseInt(props.ctx.currentPlayer)];

  return (
    <div className="coachInfo">
      <div className="playerId"><div className='logo' style={{ backgroundColor: playerTeam.color}}></div><span>{playerTeam.name}</span></div>
    </div>
  );

}

const PlayerInfo = (props) => {

  let playerTeam = props.G.teams[props.selectedPlayer.team];

  return (
    <div className="playerInfo">
      <div className="playerId"><div className='logo' style={{ backgroundColor: playerTeam.color}}></div><span>{props.selectedPlayer.number} {props.selectedPlayer.stats.name}</span></div>
      <div className="playerStats">MA: {props.selectedPlayer.stats.MA} ST: {props.selectedPlayer.stats.ST}  AG: {props.selectedPlayer.stats.AG}  AV: {props.selectedPlayer.stats.AV} </div>
      <div className="playerSkills">{props.selectedPlayer.stats.skills.map( (skill) => (<div key={skill}>{skill}</div>) )} </div>
    </div>
  );
}

const PlayerCard = (props) => {


  if (props.selectedPlayer == null) {
    return '';
  }

  let actions = [];

  // TODO: Restrict to the actual valid moves
  if (props.ctx.phase === gamePhase.PICKACTION &&
      props.G.activeTeam === props.selectedPlayer.team &&
      props.selectedPlayer.action == null) {

    actions.push({action: playerAction.MOVE, label: "Move"});
    actions.push({action: playerAction.BLOCK, label: "Block"});
    actions.push({action: playerAction.BLITZ, label: "Blitz"});
    actions.push({action: playerAction.PASS, label: "Pass"});
  }

  let movingPlayerAction = [];

  if (props.ctx.phase === gamePhase.PLAYERMOVE) {

    let activePlayer = getPlayer(props.G, props.G.activePlayerID);

    movingPlayerAction = props.ctx.allowedMoves.filter((action) => mapMoves.includes(action));

    // We remove the "move" actions
    movingPlayerAction = movingPlayerAction.filter((action) => action !== moves.MOVE);

    // Remove block as a possible action if the target player is on the same team or if the
    // target player is out of reach
    if ( (props.selectedPlayer.team === activePlayer.team) ||
         !Coord.isAdjacent(props.selectedPlayer.location, activePlayer.location) ) {

      movingPlayerAction = movingPlayerAction.filter((action) => action !== moves.BLOCK);
    }
  }

  return (
    <div>
      <PlayerInfo {...props} />
      <Toolbar>
      {
        actions.map( (action) => <button key={ "playeractin" + action.action}
                                         onClick={ () => { props.setSelectedPlayerID({}); props.moves.startAction(props.selectedPlayer, action.action);} }>
                                    {action.label}
                                 </button>)
        .concat(movingPlayerAction.map( (action) =>
                                 <button key={ "movingAction:" + action}
                                         onClick={ () => { props.setSelectedPlayerID({}); props.moves[action](props.selectedPlayer.location);} }>
                                    {action}
                                 </button>
                                ))
      }
      </Toolbar>
    </div>
  );
}

const SelectedPlayerPopup = (props) => {

  let selectedPlayer = (('team' in props.selectedPlayerID) ? getPlayer(props.G, props.selectedPlayerID) : null);

  if (selectedPlayer!= null) {

    let dialogPosision = getTokenPosition(selectedPlayer.location);

    if (selectedPlayer.state === playerState.BENCH) {
      dialogPosision = getTokenPosition({x: 12, y: 1 + selectedPlayer.team * 13});
    }

    // Adjust popup position to clear the sides
    if (selectedPlayer.location.x > 2) {
      dialogPosision.left -= 20;
    }
    if (selectedPlayer.location.x > 10) {
      dialogPosision.left -= 40;
    }
    if (selectedPlayer.location.y > 2) {
      dialogPosision.top -= 20;
    }
    if (selectedPlayer.location.y > 20) {
      dialogPosision.top -= 40;
    }


    return (
      <div className="popupStyle"
           style={ {...dialogPosision} } >
        <PlayerCard {...props} selectedPlayer={selectedPlayer} />
      </div>
    );
  }
  else {
    return null;
  }
}

const Sideboard = (props) => {

  return (
    <div className="sideboard">
      <div>{props.team.name}</div>
      <div className="playerBin"
           onClick={ (e) => { e.stopPropagation(); props.onClickReservesBox() }}>
        {
          props.players.filter( (player) => (player.state===playerState.BENCH))
            .map( (player, index) => (
                <Player key={player.team + ':' + player.number}
                        team={props.team}
                        onClickPlayer={props.onClickPlayer}
                        player={{
                                 ...player,
                                 location: { x: Math.floor(index / 4), y: (index % 4)}
                               }}  />
              ))

        }
      </div>
      <div className="playerBin">
        {
          props.players.filter( (player) => (player.state===playerState.KO))
            .map( (player, index) => (
                <Player key={player.team + ':' + player.number}
                        team={props.team}
                        player={{
                                 ...player,
                                 location: { x: Math.floor(index / 4), y: (index % 4)}
                               }}  />
              ))

        }
      </div>
      <div className="playerBin">
        {
          props.players.filter( (player) => (player.state===playerState.CASUALTY))
            .map( (player, index) => (
                <Player key={player.team + ':' + player.number}
                        team={props.team}
                        player={{
                                 ...player,
                                 location: { x: Math.floor(index / 4), y: (index % 4)}
                               }}  />
              ))

        }
      </div>
    </div>
  );
}

const GameUI = (props) => {

  const [selectedPlayerID, setSelectedPlayerID] = useState({});


  const onClickPlayer = (player) => {

    setSelectedPlayerID({team: player.team, number: player.number});
  }

  const onClickCell = (coordinates) => {

    if (props.ctx.phase === gamePhase.KICKOFF) {

      props.moves.placeBall(coordinates);
      return;
    }


    let pickedPlayer = { ...selectedPlayerID};

    // Picking an empty cell
    setSelectedPlayerID({});

    if (props.ctx.phase === gamePhase.PLAYERMOVE) {

      props.moves.move(coordinates);
    }
    else if (props.ctx.phase === gamePhase.SETUP) {

      if ( 'team' in pickedPlayer &&
          (pickedPlayer.team === props.G.activeTeam) ) {
        // Can only move our player.
        props.moves.setupPlayer(pickedPlayer, coordinates);
      }
    }
  }

  const onClickReservesBox = (team) => {

    let pickedPlayer = { ...selectedPlayerID};

    // Picking an empty cell
    setSelectedPlayerID({});

    if (props.ctx.phase === gamePhase.SETUP) {

      if ( 'team' in pickedPlayer &&
          (pickedPlayer.team === props.G.activeTeam) &&
          (pickedPlayer.team === team) ) {

        // Can only move our player.
        props.moves.setupPlayer(pickedPlayer, {x: -1, y:-1});
      }
    }
  }

  return (

    <div className="gameUI">
      <Scoreboard>
        <GameBanner />
        <Team ctx={props.ctx} G={props.G} teamId={0} />
        <ActiveCoachArea selectedPlayerID={selectedPlayerID} setSelectedPlayerID={setSelectedPlayerID} {...props} />
        <Team ctx={props.ctx} G={props.G} teamId={1} />
      </Scoreboard>
      <CoachPrompt {...props}>
      </CoachPrompt>
      <div className="playArea">
        <div className="board" >
          <Pitch onClickCell={(coordinates) => { onClickCell(coordinates)} } />
          <div className="sideBoardArea">
            <Sideboard team={props.G.teams[0]}
                       onClickReservesBox={ () => { onClickReservesBox(0) } }
                       onClickPlayer={ (player) => { onClickPlayer(player) } }
                       players={props.G.players.filter( (player) => (player.team === 0))} />
            <Sideboard team={props.G.teams[1]}
                       onClickReservesBox={ () => { onClickReservesBox(1) } }
                       onClickPlayer={ (player) => { onClickPlayer(player) } }
                       players={props.G.players.filter( (player) => (player.team === 1))} />
          </div>
          {
            props.G.players.filter( (player) => playerStateOnBoard.includes(player.state))
              .map( (player) => (
                  <Player activePlayerID={props.G.activePlayerID}
                          key={player.team + ':' + player.number}
                          onClickPlayer={ () => { onClickPlayer(player) } }
                          G={props.G} player={player} />
                ))
          }
          <Ball G={props.G} />
          <History G={props.G} />
          <SelectedPlayerPopup selectedPlayerID={selectedPlayerID} setSelectedPlayerID={setSelectedPlayerID} {...props}>
          </SelectedPlayerPopup>
        </div>
      </div>

    </div>
  );
}
export default GameUI
