import { INVALID_MOVE } from 'boardgame.io/core';

import { playerState, isInbounds, prompts, getPlayer, movePlayer, otherTeam, findPlayerInPosition, moves, playerGoesDown } from './game';
import { Coord } from './utils'

// Is the target location a valid target for a block action
function playerCanBlockThere(G, activePlayer, location) {

  const blockTarget = findPlayerInPosition(G, location);

  return (Coord.isAdjacent(activePlayer.location, location) &&
          blockTarget != null &&
          blockTarget.team !== activePlayer.team &&
          blockTarget.state === playerState.READY);
}

function countDiceForBlock(strength1, strength2) {

  let largerStrength = Math.max(strength1, strength2);
  let smallerStrength = Math.min(strength1, strength2);

  if (largerStrength > (smallerStrength * 2) ) {
    return 3;
  }
  else if (largerStrength > smallerStrength) {
    return 2;
  }
  else {
    return 1;
  }
}


// Given the position of the blocker and blocked player, computes the
// potential places where the blocked player is pushed back
function pushbackCoords(blockerLocation, blockeeLocation) {

  if (blockerLocation.x < blockeeLocation.x) {

    if (blockerLocation.y < blockeeLocation.y) {

      return [ { x: blockeeLocation.x, y: blockeeLocation.y +1} , { x: blockeeLocation.x+1, y: blockeeLocation.y +1} , { x: blockeeLocation.x+1, y: blockeeLocation.y }]
    }
    else if (blockerLocation.y > blockeeLocation.y) {

      return [ { x: blockeeLocation.x, y: blockeeLocation.y -1} , { x: blockeeLocation.x+1, y: blockeeLocation.y -1} , { x: blockeeLocation.x+1, y: blockeeLocation.y }]
    }
    else {

      return [ { x: blockeeLocation.x+1, y: blockeeLocation.y -1} , { x: blockeeLocation.x+1, y: blockeeLocation.y } , { x: blockeeLocation.x+1, y: blockeeLocation.y+1 }]
    }
  }
  else if (blockerLocation.x > blockeeLocation.x) {

    if (blockerLocation.y < blockeeLocation.y) {

      return [ { x: blockeeLocation.x-1, y: blockeeLocation.y } , { x: blockeeLocation.x-1, y: blockeeLocation.y +1} , { x: blockeeLocation.x, y: blockeeLocation.y+1 }]
    }
    else if (blockerLocation.y > blockeeLocation.y) {

      return [ { x: blockeeLocation.x-1, y: blockeeLocation.y} , { x: blockeeLocation.x-1, y: blockeeLocation.y -1} , { x: blockeeLocation.x, y: blockeeLocation.y-1 }]
    }
    else {

      return [ { x: blockeeLocation.x-1, y: blockeeLocation.y -1} , { x: blockeeLocation.x-1, y: blockeeLocation.y } , { x: blockeeLocation.x-1, y: blockeeLocation.y+1 }]
    }
  }
  else if (blockerLocation.y < blockeeLocation.y) {

    return [ { x: blockeeLocation.x-1, y: blockeeLocation.y +1} , { x: blockeeLocation.x, y: blockeeLocation.y+1 } , { x: blockeeLocation.x+1, y: blockeeLocation.y+1 }]
  }
  else {

    return [ { x: blockeeLocation.x-1, y: blockeeLocation.y -1} , { x: blockeeLocation.x, y: blockeeLocation.y-1 } , { x: blockeeLocation.x+1, y: blockeeLocation.y-1 }]
  }
}

function findValidPushback(G, ctx, blockerLocation, blockeeLocation) {

  var valid = pushbackCoords(blockerLocation, blockeeLocation);
  var clear = valid.filter( (current) => ( findPlayerInPosition(G, current) == null) );
  var clearAndInbounds = clear.filter( (current) => ( isInbounds(current) ) );

  if (clearAndInbounds.length > 0) {
    return clearAndInbounds;
  }

  if (clear.length > 0) {
    return clear;
  }

  return valid;
}


function pushPlayerBack(G, ctx, player, location) {
  movePlayer(G, ctx, player, location);
}

export function finishBLockAction(G, ctx, originalPrompt, response) {

  const activePlayer = getPlayer(G, originalPrompt.actingPlayerID);
  const blockTarget  = getPlayer(G, originalPrompt.defenderPlayerID);
  let follow = true; // TODO: deal with follow

  // All responses except accept need to be logged here
  if (response.response !== 'accept')  {
    G.historyLog.push({ type: 'block', coordinates: activePlayer.location, player: activePlayer, diceRoll: originalPrompt.diceRoll, selected: response.response});
  }

  switch (response.response) {

    case 'reroll':

      // TODO: Make sure reroll is allowed
      G.teams[originalPrompt.coach].remainingRerolls--;

      const blockDice = ctx.random.D6(originalPrompt.diceRoll.length);

      // Reroll the dice and dedupe
      originalPrompt.diceRoll = blockDice.map( (roll) => (blockDie[roll]));
      originalPrompt.responses = originalPrompt.diceRoll.filter( (value, index, self) => (self.indexOf(value) === index) );

      if (originalPrompt.responses.length === 1)
        return finishBLockAction(G, ctx, originalPrompt, { response: originalPrompt.responses[0]});

      // Prompt again
      originalPrompt.coach = originalPrompt.strongestPlayer;
      G.coachPrompts.push(originalPrompt);

      break;

    case 'accept':
      // Should only occur if we have multiple dice and the strongest player is
      // the opponent
      originalPrompt.responses = originalPrompt.diceRoll.filter( (value, index, self) => (self.indexOf(value) === index) );

      if (originalPrompt.responses.length === 1)
        return finishBLockAction(G, ctx, originalPrompt, { response: originalPrompt.responses[0]});

      // Prompt again
      originalPrompt.coach = originalPrompt.strongestPlayer;
      G.coachPrompts.push(originalPrompt);

      break;

    case "AttakerDown":
      playerGoesDown(G, ctx, activePlayer);
      break;
    case "BothDown":
      if (!activePlayer.stats.skills.includes("block")) {
        playerGoesDown(G, ctx, activePlayer);
      }

      if (!blockTarget.stats.skills.includes("block")) {
        playerGoesDown(G, ctx, blockTarget);
      }

      break;
    case "pushback":
      {
        // pushed
        let pushbackCoords = findValidPushback(G, ctx, activePlayer.location, blockTarget.location);

        if (pushbackCoords.length > 0) {

          pushPlayerBack(G, ctx, blockTarget, pushbackCoords[0])

          if (follow)
            movePlayer(G, ctx, activePlayer, originalPrompt.coordinates);
        }
        break;
      }
    case "Stumble":
      {
        // Stumble
        let pushbackCoords = findValidPushback(G, ctx, activePlayer.location, blockTarget.location);

        if (pushbackCoords.length > 0) {

          pushPlayerBack(G, ctx, blockTarget, pushbackCoords[0])

          if (follow)
            movePlayer(G, ctx, activePlayer, originalPrompt.coordinates);
        }

        if (!blockTarget.stats.skills.includes("dodge")) {
          playerGoesDown(G, ctx, blockTarget);
        }
        break;
      }
    case "DefenderDown":
      {
        // Defender down
        let pushbackCoords = findValidPushback(G, ctx, activePlayer.location, blockTarget.location);

        if (pushbackCoords.length > 0) {

          pushPlayerBack(G, ctx, blockTarget, pushbackCoords[0])

          if (follow)
            movePlayer(G, ctx, activePlayer, originalPrompt.coordinates);
        }

        playerGoesDown(G, ctx, blockTarget);

        break;
      }
    default:
      // Not possible
      break;
  }
}

const blockDie = {
  '1': "AttakerDown",
  '2': "BothDown",
  '3': "pushback",
  '4': "pushback",
  '5': "Stumble",
  '6': "DefenderDown",
}

export function takeBlockAction(G, ctx, location) {

  const activePlayer = getPlayer(G, G.activePlayerID);
  const blockTarget = findPlayerInPosition(G, location);

  // Validate that this move is legal
  if (!playerCanBlockThere(G, activePlayer, location))
    return INVALID_MOVE;

  // Attempt the block
  activePlayer.action.push({ move: moves.BLOCK, coordinates: location });
  activePlayer.movesRemaining = activePlayer.movesRemaining - 1;

  let attackerStrength = activePlayer.stats.ST;
  let defenderStrength = blockTarget.stats.ST;
  let numberOfDice = countDiceForBlock(attackerStrength, defenderStrength);
  let blockDice = ctx.random.D6(numberOfDice);

  let coachPrompt = {
    type: prompts.BLOCK,
    coordinates: location,
    coach: activePlayer.team,
    strongestPlayer: ( (attackerStrength >= defenderStrength) ? activePlayer.team : otherTeam(activePlayer.team)),
    actingPlayerID: { team: activePlayer.team, number: activePlayer.number },
    defenderPlayerID: { team: blockTarget.team, number: blockTarget.number },
    diceRoll: blockDice.map( (roll) => (blockDie[roll])),
    responses: [],
  }

  // First thing, check if we have possible Rerolls
  if (G.teams[activePlayer.team].remainingRerolls > 0) {

    coachPrompt.responses.push('reroll');
  }

  if (coachPrompt.strongestPlayer === activePlayer.team) {

    // Add the dice roll and dedupe
    coachPrompt.responses = coachPrompt.responses.concat(blockDice.map( (roll) => (blockDie[roll])));
    coachPrompt.responses = coachPrompt.responses.filter( (value, index, self) => (self.indexOf(value) === index) );
  }
  else {
    coachPrompt.responses.push('accept');
  }

  if (coachPrompt.responses.length === 1) {

    return finishBLockAction(G, ctx, coachPrompt, { response: coachPrompt.responses[0]});
  }
  else {
    G.coachPrompts.push(coachPrompt);
  }
}
