
export const Coord = {
  isSame : (loc1, loc2) => {
      return ( (loc1.x === loc2.x) && (loc1.y === loc2.y) );
    },

  isAdjacent : (loc1, loc2) => {
      return ( (Math.abs(loc1.x - loc2.x) <= 1) && (Math.abs(loc1.y - loc2.y) <= 1) );
    },

  isOnTeamSide: (team, loc) => {
      return ( (team === 0) ? loc.y < 13: loc.y >= 13);
    },
}

export function measurePassLength(source, destination) {

  var length = Math.floor(Math.sqrt(Math.pow(source.x - destination.x, 2) + Math.pow(source.y - destination.y, 2)));

  return Math.min(-2,1 - Math.floor(length / 4));
}
