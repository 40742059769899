import { INVALID_MOVE } from 'boardgame.io/core';

import { getPlayer, prompts, movePlayer, countTackleZones, otherTeam, findPlayerInPosition, moves, rollForAgility, playerGoesDown } from './game';
import { Coord } from './utils'



// Is this a valid square for the player to move
function playerCanMoveThere(G, activePlayer, location) {

  const playerInTargetSquare = findPlayerInPosition(G, location);

  return (Coord.isAdjacent(activePlayer.location, location) &&
          playerInTargetSquare == null);

}

// Respond to a move action
export function takeMoveAction(G, ctx, location) {

  const activePlayer = getPlayer(G, G.activePlayerID);
  const leavingTackleZones = countTackleZones(G, otherTeam(activePlayer.team), activePlayer.location);

  // Validate that this move is legal
  if (!playerCanMoveThere(G, activePlayer, location) ||
      activePlayer.movesRemaining <= 0)
    return INVALID_MOVE;

  // Make the move
  // TODO: log payload
  activePlayer.action.push({ move: moves.MOVE, coordinate: location });
  movePlayer(G, ctx, activePlayer, location);
  activePlayer.movesRemaining = activePlayer.movesRemaining - 1;

  // Test for dodge
  if (leavingTackleZones > 0)
  {
    takeDodgeAction(G, ctx, activePlayer);
  }
}

function takeDodgeAction(G, ctx, activePlayer) {

  activePlayer.action.push({ move: 'dodge', coordinate: activePlayer.location });

  let coachPrompt = {
      type: prompts.DODGE,
      coach: activePlayer.team,
      actingPlayerID: { team: activePlayer.team, number: activePlayer.number},
      diceRoll: rollForAgility(ctx, activePlayer, 1 - countTackleZones(G, otherTeam(activePlayer.team), activePlayer.location)),
      coordinates: activePlayer.location,
      responses: ['accept']
    };

    G.historyLog.push({ type: 'dodge', coordinates: activePlayer.location, player: activePlayer, diceRoll: [coachPrompt.diceRoll.roll]});

    // Automatic reroll for skill
    if (!coachPrompt.diceRoll.success) {

      if (activePlayer.stats.skills.includes('dodge')) {

        coachPrompt.diceRoll = rollForAgility(ctx, activePlayer, coachPrompt.diceRoll.modifier);

        G.historyLog.push({ type: 'dodge-skillReroll', coordinates: activePlayer.location, player: activePlayer, diceRoll: [coachPrompt.diceRoll.roll]});
      }
      else if (G.teams[activePlayer.team].remainingRerolls > 0){
        // If there are team rerolls remaining, prompt the coach
        coachPrompt.responses.push('reroll');
      }
    }

    if (coachPrompt.responses.length === 1) {

      finishDodgeAction(G, ctx, coachPrompt, { response: coachPrompt.responses[0]});
    }
    else {

      G.coachPrompts.push(coachPrompt);
    }
}

export function finishDodgeAction(G, ctx, originalPrompt = null, response = null) {

  const activePlayer = getPlayer(G, originalPrompt.actingPlayerID);
  let   rollForPass  = originalPrompt.diceRoll;

  // If we are failing and the coach asked for a reroll
  if (!rollForPass.success &&
      response.response === 'reroll') {

    rollForPass = rollForAgility(ctx, activePlayer, rollForPass.modifier);
    G.historyLog.push({ type: 'dodge-teamReroll', coordinates: activePlayer.location, player: activePlayer, diceRoll: [rollForPass.roll]});

    G.teams[activePlayer.team].remainingRerolls--;
  }

  // Did we succeed
  if (!rollForPass.success) {

    activePlayer.action.push({ move: 'goingDown', coordinate: activePlayer.location });

    playerGoesDown(G, ctx, activePlayer);
  }
}
