import React from 'react'

import { fieldSize } from './game'

const Pitch = (props) => {

  // Builds a UI-focus representation of the game board.
  const buildBoard = () => {

    var board = [];

    for (let y = 0; y < fieldSize.length; y++) {
      var row = []

      for (let x=0;x< fieldSize.width;x++) {

        row.push ( { coordinates: { x: x, y: y } } );
      }

      board.push(row);
    }

    for (let x=0;x<fieldSize.width;x++) {
      board[0][x].isEndZone = true;
      board[fieldSize.length-1][x].isEndZone = true;
    }

    return board;
  }

  return (
    <div className="pitch">
      {
        buildBoard().map( (row, rowIndex) => (
          <div className="row" key={rowIndex.toString()} >
          {
            row.map( (cell, cellIndex) => (
              <div key={JSON.stringify(cell.coordinates)} 
                  onClick={ (e) => {
                                      e.stopPropagation();
                                      props.onClickCell(cell.coordinates);
                                    }
                            }
                  className={ 'cell' +
                              (cell.isEndZone ? ' endZone' : '') +
                              (((cell.coordinates.x===4) || (cell.coordinates.x===11)) ? ' cellLeftEdge' : '') +
                              ((cell.coordinates.y===12) ? ' cellBottomEdge' : '') }>
              </div>
            ))
          }
          </div>
        ))
      }
    </div>
  );
}

export default Pitch;
