import React from 'react'

import {getTokenPosition } from './gameui'
import { Coord } from './utils'

const Arrow = (props) => {

  let from = getTokenPosition(props.from);
  let to = getTokenPosition(props.to);

  from.left += 20;
  from.top += 20;
  to.left += 20;
  to.top += 20;

  return (
    <svg style={{width: '100%', height: '100%', zIndex:200, pointerEvents: 'none', position:'absolute'}}
    xmlns="http://www.w3.org/2000/svg">

      <defs>
        <filter id="dropShadow" height="200%" width="200%" filterUnits="userSpaceOnUse">
          <feDropShadow dx="2" dy="2" stdDeviation="2"/>
        </filter>

        <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5"
            markerWidth="6" markerHeight="6"
            className={"arrow_" + props.className + "End"}
            orient="auto-start-reverse">
          <path d="M 0 0 L 10 5 L 0 10 z" />
        </marker>

      </defs>

      <line x1={from.left} y1={from.top} x2={to.left} y2={to.top} className={"arrow_" + props.className} filter="url(#dropShadow)"/>

    </svg>
  )
}

const DiceRoll = (props) => {

  const blockDieMap = {
    "AttakerDown": "A",
    "BothDown": "B",
    "pushback": "P",
    "Stumble": "S",
    "DefenderDown": "D"
  }

  return (
    <>
    {
      props.dice.map( (die, index) => (
          <span key="index" className={'die ' + ((props.selected && props.selected===die) ? 'selectedDie' : '')}>
            {
              (props.type && props.type === 'block') ?
              blockDieMap[die] :
              die
            }
          </span>
        ))
    }
    </>
  );
}

export const History = (props) => {

  let markers = [];

  const BlockRoll = (props) => {

    let coordinates = getTokenPosition(props.roll.coordinates);

    coordinates.left += 2;
    coordinates.top += 2;

    let markerCount = markers.reduce( (total, item) => (Coord.isSame(item, props.roll.coordinates) ? (total + 1): total), 0);
    markers.push(props.roll.coordinates);

    coordinates.top += (20 * markerCount);

    return (
      <div key={'history:' + props.index} className="historyTag agilityRoll"
           style={ {zIndex: 201 + props.index, ...coordinates, pointerEvents: 'none', position:'absolute'}}>
        {props.children} : <DiceRoll type="block" dice={props.roll.diceRoll} selected={props.roll.selected}/>
      </div>
    );
  }

  const AgilityRoll = (props) => {

    let coordinates = getTokenPosition(props.roll.coordinates);

    coordinates.left += 2;
    coordinates.top += 2;

    let markerCount = markers.reduce( (total, item) => (Coord.isSame(item, props.roll.coordinates) ? (total + 1): total), 0);
    markers.push(props.roll.coordinates);

    coordinates.top += (20 * markerCount);

    return (
      <div key={'history:' + props.index} className="historyTag agilityRoll"
           style={ {zIndex: 201 + props.index, ...coordinates, pointerEvents: 'none', position:'absolute'}}>
        {props.children} : <DiceRoll dice={props.roll.diceRoll}/>
      </div>
    );
  }

  const InjuryRoll = (props) => {

    let coordinates = getTokenPosition(props.roll.coordinates);

    coordinates.left += 2;
    coordinates.top += 2;

    let markerCount = markers.reduce( (total, item) => (Coord.isSame(item, props.roll.coordinates) ? (total + 1): total), 0);
    markers.push(props.roll.coordinates);

    coordinates.top += (20 * markerCount);

    return (
      <div key={'history:' + props.index} className="historyTag agilityRoll"
           style={ {zIndex: 201 + props.index, ...coordinates, pointerEvents: 'none', position:'absolute'}}>
        {props.children} : <DiceRoll dice={props.roll.diceRoll}/>
      </div>
    );
  }


  return (
    <div style={{width: '100%', height: '100%', zIndex:200, pointerEvents: 'none', position:'absolute'}}>
      {
        props.G.historyLog.map( (item, index) => {

          switch (item.type) {
            case 'move':
              return (<Arrow key={'history:' + index} index={index} from={item.from} to={item.to} className="player" />);
            case 'scatter':
            case 'pass':
                return (<Arrow key={'history:' + index} index={index} from={item.from} to={item.to} className="ball"/>);
            case 'block':
                return (<BlockRoll key={'history:' + index} index={index} roll={item} >B</BlockRoll>);
            case 'pickup':
            case 'pickup-skillReroll':
            case 'pickup-teamReroll':
              return (<AgilityRoll key={'history:' + index} index={index} roll={item} >P</AgilityRoll>);
            case 'dodge':
            case 'dodge-skillReroll':
            case 'dodge-teamReroll':
              return (<AgilityRoll key={'history:' + index} index={index} roll={item} >D</AgilityRoll>);
            case 'throw':
            case 'throw-skillReroll':
            case 'throw-teamReroll':
              return (<AgilityRoll key={'history:' + index} index={index} roll={item} >T</AgilityRoll>);
            case 'injuryRoll':
              return (<InjuryRoll key={'history:' + index} index={index} roll={item} >I</InjuryRoll>);
            case 'armorRoll':
              return (<InjuryRoll key={'history:' + index} index={index} roll={item} >A</InjuryRoll>);
            default:
              return null;
          }
        })
      }
    </div>
  );
}

export default History;
